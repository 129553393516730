import React, { FC, ReactNode } from 'react'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import themeModeReducer from '../reducers/reducer.themeMode'
import translatiorReducer from '../reducers/reducer.translator'
import authReducer from '../reducers/reduser.user'

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import storage from 'redux-persist/lib/storage'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { authApi } from '../api/api.auth'

interface ProviderProps {
  children: ReactNode
}

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['authApi']
}

const rootRedusers = combineReducers({
  themeMode: themeModeReducer,
  transaltor: translatiorReducer,
  auth_token: authReducer,
  [authApi.reducerPath]: authApi.reducer
})


const persistedReducer = persistReducer(rootPersistConfig, rootRedusers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
      .concat(authApi.middleware)
})

setupListeners(store.dispatch)

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const AppStore: FC<ProviderProps> = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  )
}

export default AppStore