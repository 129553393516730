import { createTheme, ThemeOptions, PaletteOptions } from '@mui/material/styles';
import { createContext, FC, ReactNode, useEffect } from 'react';
import { ThemeProvider } from '@mui/material'
import useThemeMode from '../hooks/useTheme';

interface AppThemeProviderProps {
    children: ReactNode
}

export const light: PaletteOptions = {
    mode: 'light',
    primary: {
        main: '#6EA8FD',
    },
    secondary: {
        main: '#CCE4F7;'
    },
    common: {
        white: '#ffffff',
    },
    background: {
        default: '#6EA8FD',
        paper: '#ffffff'
    },
    text: {
        primary: '#6EA8FD;',
        secondary: '#6EA8FD',
    },
    grey: {
        "100": 'red'
    },
    action: {
        active: '#ffbdd3',
        focus: '#ffbdd3',
        hover: "#3E81F5"
    }
}

export const dark: PaletteOptions = {
    mode: 'dark',
    primary: {
        main: '#CCE4F7'
    }
}

export const AppThemeContext = createContext

const AppThemeProvider: FC<AppThemeProviderProps> = ({ children }) => {
    const mode = useThemeMode()
    const theme: ThemeOptions = createTheme({
        palette: {
            ...(mode === 'light' ? light : dark)
        },
        components: {
            MuiTextField: {
                defaultProps: {
                    variant: 'outlined',
                    fullWidth: true
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        ...(
                            theme.palette.mode === 'light' && {
                                fieldset: {
                                    borderColor: theme.palette.secondary.main
                                }
                            }),
                    })
                }
            },
        }
    })

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>

    )
}

export default AppThemeProvider


