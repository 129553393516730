import React, { Children, FC } from "react";
import { Button, ButtonProps, Typography } from "@mui/material"
import { JsxElement } from "typescript";
import { Action } from "@remix-run/router";
import { hover } from "@testing-library/user-event/dist/hover";

const CustomButton: FC<ButtonProps> = ({ children, ...props }: ButtonProps) => {
    return (
        <Button
            sx={{
                backgroundColor: theme => theme.palette.background.default,
                ":hover":{
                    backgroundColor: theme => theme.palette.action.hover
                },
                color: theme => theme.palette.common.white, 
            }} 
            {...props}
        >
            {typeof children === 'string'
                && <Typography>
                    {children}
                </Typography>
                || children
            }
        </Button>
    )
}

export default CustomButton