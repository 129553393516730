import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import type { RootState } from '../../app/AppStore'
import CustomButton from "./CustomButton";
import { useTranslation } from 'react-i18next'
import { switchLanguage } from "../../reducers/reducer.translator";

const ChangeLanguage = () => {
    const [language, setLanguage] =  useState(useSelector((state: RootState) => state.transaltor.language))
    const dispatch = useDispatch()    
    //const { t, i18n } = useTranslation() 
    

    const changeLanguageHandler = () => {
        const new_lang = language === 'ru' ? 'en' : 'ru'
        dispatch(switchLanguage(new_lang))
        setLanguage(new_lang)
        //i18n.changeLanguage(new_lang)
    }

    return (
        <CustomButton
            onClick={changeLanguageHandler}
        >
            {language}
        </CustomButton>
    )
}

export default ChangeLanguage