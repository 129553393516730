import  Auth  from '../types/types.login'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://knbase.ru/',
    }),
    endpoints: (builder) =>  ({
        auth: builder.mutation<Auth, Partial<Auth>>({
            query: (body) => ({
                url:'auth/token/login/',
                method: 'POST',
                body
            })
        })
    }) 
})

export const { useAuthMutation } = authApi