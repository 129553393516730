import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from "react-router-dom";
import routers from './routers/routers';
import AppStore from './app/AppStore';
import AppThemeProvider from './app/theme';
import AppLanguage from './app/AppLanguage';
import { SnackbarProvider } from 'notistack';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppStore>
      <AppThemeProvider>
        <AppLanguage>
          <SnackbarProvider>
            <RouterProvider router={routers} />
          </SnackbarProvider>
        </AppLanguage>
      </AppThemeProvider>
    </AppStore >
  </React.StrictMode >
);

reportWebVitals();
