import React, { useState, ChangeEvent, useContext } from 'react';
import { Box, Paper, Typography, Divider, TextField, Stack, CircularProgress } from '@mui/material'
import CustomButton from '../components/button/CustomButton';
import ChangeThemeButton from '../components/button/ChangeThemeButton';
import ChangeLanguage from '../components/button/ChangeLanguage';
import { useTranslation } from 'react-i18next';
import { useSnackbar, VariantType } from 'notistack';
import { useAuthMutation } from '../api/api.auth';
import { useDispatch } from 'react-redux'
import { setUser } from '../reducers/reduser.user';
import type Auth from '../types/types.login'
import type userTokenState from '../types/types userToken'


const Login = () => {
    const { t } = useTranslation();
    const [usrName, setUserName] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [login, { isLoading }] = useAuthMutation()
    const dispatch = useDispatch()
    console.log(isLoading)

    const { enqueueSnackbar: snack } = useSnackbar()

    const onChangeLogin = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setUserName(value)
    }

    const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setPassword(value)
    }

    const HendlerLogin = async () => {
        if (usrName.length === 0) return snack('Заполните имя', { variant: 'info' })
        if (password.length === 0) return snack('Заполните пароль', { variant: 'info' })

        try {
            const body: Auth = {
                username: usrName,
                password: password
            }
            await login(body).unwrap()
                .then((data: any) => {
                    dispatch(setUser(data))
                })
                .catch((error) => {                    
                    snack(JSON.stringify(error.data), { variant: 'error' })
                })
        } catch (error: any) {
            snack("Ошибка", { variant: 'error' })
        }
    }

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme => theme.palette.background.default
            }}
        >

            <Paper
                elevation={3}
                sx={{
                    height: '550px',
                    width: '850px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    backgroundColor: theme => theme.palette.background.paper
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        width: '50%'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Stack
                            direction="row"
                        >
                            <Typography
                                sx={{
                                    color: theme => theme.palette.text.secondary,
                                    fontWeight: 'bold',
                                    fontSize: 48,
                                }}
                            >
                                KN
                            </Typography>

                            <Typography
                                sx={{
                                    backgroundColor: theme => theme.palette.primary.main,
                                    color: theme => theme.palette.common.white,
                                    borderBottomRightRadius: 5,
                                    borderTopLeftRadius: 5,
                                    fontWeight: 'bold',
                                    fontSize: 48,
                                    pl: 1,
                                    pr: 1,
                                    ml: 1
                                }}
                            >
                                BASE
                            </Typography>
                        </Stack>
                    </Box>

                    <Stack
                        spacing={1}
                    >
                        <TextField
                            label={t("Login")}
                            helperText={t('Enter your name')}
                            onChange={onChangeLogin}
                        />
                        <TextField
                            label={t("Password")}
                            type="password"
                            helperText={t('Enter ypur password')}
                            onChange={onChangePassword}
                        />
                    </Stack>

                    <CustomButton
                        onClick={HendlerLogin}
                    >
                        {
                            isLoading
                                ? <CircularProgress size={24}/>
                                : t('Log in')
                        }

                    </CustomButton>

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                    >
                        <ChangeThemeButton />
                        <ChangeLanguage />

                    </Stack>
                </Box>

                <Divider
                    orientation="vertical"
                    flexItem
                />

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 250,
                            width: 80
                        }}
                    >
                        <img src="http://qrcoder.ru/code/?http%3A%2F%2Fknbase.ru&4&0" alt="qr-code" />
                    </Box>
                </Box>
            </Paper>
        </Box >
    )
}

export default Login