import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PaletteMode } from '@mui/material'

export interface Theme {
    mode: PaletteMode
}

const initialState: Theme = {
    mode: 'light'
}

export const themeModeSlice = createSlice({
    name: 'themeMode',
    initialState,
    reducers: {
        switchTheme: (state, action: PayloadAction<PaletteMode>) => {
            state.mode = action.payload
        }
    }
})

export const { switchTheme } = themeModeSlice.actions
export default themeModeSlice.reducer