import i18n from "i18next";
import { ReactNode, FC, useEffect } from "react";
import { initReactI18next } from "react-i18next";
import { I18nextProvider } from 'react-i18next';
import useTranslator from "../hooks/useLanguage";

interface ApplLanguageOptions {
    children: ReactNode
}

const resources = {
    en: {
        translation: {
            'Login': 'Login',
            'Log in' : 'log in',
            'Password': 'Password',            
            'Enter your name': 'Enter your name',
            'Enter ypur password':'Enter ypur password',
            'Authorisation error': 'Authorisation error' 
        }
    },
    ru: {
        translation: {
            'Login': 'Логин',
            'Log in':'Войти',
            'Password': 'Пароль',
            'Enter your name': 'Введите ваше логин',
            'Enter ypur password': 'Введите выш пароль',
            'Authorisation error': 'Ошибка авторизации'
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'ru',

        interpolation: {
            escapeValue: false
        }
    });

const AppLanguage: FC<ApplLanguageOptions> = ({ children }) => {
    const language = useTranslator();
    
    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language])

    return (
        <I18nextProvider i18n={i18n}>
            {children}
        </I18nextProvider>
    )
}

export default AppLanguage;