import React from "react";
import { Container, Typography, Link } from "@mui/material";

const Index = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vh",
        height: "100vh",
      }}
    >
      <Typography>Sorry, this page is not working!</Typography>
      <Link href="https://knbase.ru">follow this link</Link>
    </Container>
  );
};

export default Index;
