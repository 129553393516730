import React, {useState} from 'react';
import { Box, } from '@mui/system';
import CustomButton from './CustomButton'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState } from '../../app/AppStore'
import { switchTheme } from '../../reducers/reducer.themeMode';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

const ChangeThemeButton = () => {
    const [theme, setTheme] = useState(useSelector((state: RootState) => state.themeMode.mode))
    const dispatch = useDispatch()

    const changeTheme = () => {        
        const new_theme = theme === 'light' ? 'dark' : 'light'
        setTheme(new_theme)
        dispatch(switchTheme(new_theme))
      }

    return (
        <Box>
            <CustomButton
                onClick={changeTheme}
            >
                <LightbulbIcon/>
            </CustomButton>
        </Box>
    );
}

export default ChangeThemeButton;