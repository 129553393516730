import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'

type language = 'en' | 'ru'
interface initialStateOptions {
    language: language
}

const initialState:initialStateOptions = {
    language:'ru'
}

const translatorSlice = createSlice({
    name: 'language',
    initialState,
    reducers:{
        switchLanguage: (state, action:PayloadAction<language>) => {
            state.language = action.payload
        }
    }

}) 

export const {switchLanguage} = translatorSlice.actions
export default translatorSlice.reducer