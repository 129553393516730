import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import userTokenState from '../types/types userToken'

const initialState: userTokenState = {
    auth_token: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (
            state:userTokenState,
            payload: PayloadAction<userTokenState>) => {
            state.auth_token = payload.payload.auth_token
        }
    }
})

export const { setUser } = userSlice.actions
export default userSlice.reducer